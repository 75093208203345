import { product } from "../../js/path";
import axios from "axios";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      items: [
        {
          text: "Back",
          href: "/product",
        },
        {
          text: "Data",
        },
      ],
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "name",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "slug",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "type",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "url",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "edit",
        },
      ],
      form: {
        id: "",
        name: "",
        slug: "",
        type: "",
        url: "",
      },
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "in_active") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      }
      let request = `${product?.getProduct}?page=${this.currentPage ?? 1}&q=${
        this.filter ?? ""
      }&filter=${txt ?? ""}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    async fetchProduct(id) {
      this.$store.commit("loader/updateStatus", true);
      let request = `${product?.getProduct}/${id}`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            const responseData = res.response;
            if (responseData.id != "" && responseData.id != null) {
              this.form.id = responseData.id;
            }
            if (responseData.name != "" && responseData.name != null) {
              this.form.name = responseData.name;
            }
            if (responseData.slug != "" && responseData.slug != null) {
              this.form.slug = responseData.slug;
            }
            if (responseData.type != "" && responseData.type != null) {
              this.form.type = responseData.type;
            }
            if (responseData.url != "" && responseData.url != null) {
              this.form.url = responseData.url;
            }
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    async updateStatus(id) {
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${product?.updateProductStatus}/${id}`;
        const data = await axios.post(url, {
          status: !this.tableData.data[index].status,
        });
        console.log(data);
        if (data.status) {
          this.tableData.data[index].status =
            !this.tableData.data[index].status;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = `${apiEndpoint}${product?.getProduct}`;
        if (this.$route.name == "edit-product") {
          url = `${apiEndpoint}${product?.getProduct}/${this.form.id}`;
        }
        let dataAppend = new FormData();
        for (var key in this.form) {
          dataAppend.append(key, this.form[key]);
        }
        if (this.$route.name == "edit-product") {
          dataAppend.append("_method", "put");
        }
        const request = await axios.post(url, dataAppend);
        const response = request?.data;
        if (response?.status) {
          console.log(response?.message, "data.message");
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: response?.message,
          });
          this.$router.push("/product");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
        this.pageNo = this.currentPage;
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
  },
  created() {
    if (
      this.$route.name == "add-product" ||
      this.$route.name == "edit-product"
    ) {
      if (this.$route.name == "edit-product") {
        this.fetchProduct(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
  mounted() {},
};
